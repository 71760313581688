var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"contact-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Contact.title')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Contact.subtitle'))+" ")])]),_c('div',{staticClass:"form-section mx-2"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t( 'Contact.contactInformation.title' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'Contact.contactInformation.subtitle.firstLine' ))),_c('br'),_vm._v(" "+_vm._s(_vm.$t( 'Contact.contactInformation.subtitle.secondLine' ))+" ")])]),_vm._m(0)])]),_c('div',{staticClass:"col-lg-7"},[_c('div',{staticClass:"right"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"d-flex flex-column",staticStyle:{"gap":"2rem"}},_vm._l((_vm.dataForm),function(data,index){return _c('span',{key:index},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t(data.label))}}),(data.type == 'email')?_c('ValidationProvider',{attrs:{"name":_vm.$t(data.label),"rules":data.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.value),expression:"data.value"}],attrs:{"type":"text","placeholder":_vm.$t(data.label)},domProps:{"value":(data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "value", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}):(
                                                data.type == 'message'
                                            )?_c('ValidationProvider',{attrs:{"name":_vm.$t(data.label),"rules":data.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(data.value),expression:"data.value"}],attrs:{"id":"message","placeholder":_vm.$t(data.label)},domProps:{"value":(data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "value", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}):_c('ValidationProvider',{attrs:{"name":_vm.$t(data.label),"rules":data.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.value),expression:"data.value"}],attrs:{"type":"text","placeholder":_vm.$t(data.label)},domProps:{"value":(data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "value", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)}),0)]),_c('button',{staticClass:"button",on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t( 'Contact.contactInformation.form.button' ))+" ")])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"links"},[_c('li',[_c('i',{staticClass:"fa fa-envelope"}),_c('a',{attrs:{"href":"mailto:info@autofaktura.com"}},[_vm._v(" info@autofaktura.com ")])]),_c('li',[_c('i',{staticClass:"fa fa-map-marker"}),_vm._v(" Mässans gata 10, Göteborg ")])])}]

export { render, staticRenderFns }